@font-face {
    font-family: "FuturaPT-Book";
    src: local("FuturaPT-Book"),
        url("./assets/fonts/FuturaPT-Book.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Bold";
    src: local("FuturaPT-Bold"),
        url("./assets/fonts/FuturaPT-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Demi";
    src: local("FuturaPT-Demi"),
        url("./assets/fonts/FuturaPT-Demi.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Light";
    src: local("FuturaPT-Light"),
        url("./assets/fonts/FuturaPT-Light.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "TenorSans";
    src: local("TenorSans-Regular"),
        url("./assets/fonts/TenorSans-Regular.ttf") format("truetype");
    font-weight: bold;
}

body {
    margin: 0px;
}

h1 {
    margin: 0px;
}

.paypal-logo-color-white {
    display: none !important;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
  }